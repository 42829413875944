$(document).ready(function () {

    $('.js-log-in').on('click', function (e) {

        e.preventDefault();

        valid = true;
        error_msg = "";

        form = $(this).closest('form');

        error_msg += form.checkRequiredFields();

        if (valid) {
            post_data = form.find('input').serialize();

            url = "../php/ajax/user.php?action=log-in";

            $.post(url, post_data, function (data) {

                try {

                    response = JSON.parse(data);

                    console.log(response);

                    if (response.success) {
                        location.href = '/';
                    }
                    else {
                        valid = false;
                        error_msg += response.error_message;
                        msg = errorify(error_msg);
                        dialogify(msg);
                    }
                }
                catch (e) {
                    valid = false;
                    error_msg += "<li>Technical error: " + data + "</li>";
                    msg = errorify(error_msg);
                    dialogify(msg);
                }

            });

        }

        if (!valid) {
            msg = errorify(error_msg);
            dialogify(msg);
        }


    });

});